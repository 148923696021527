import React, { useState } from 'react';
import { Container } from './styles.css'

function Settings() {


    return (
        <Container>
            SETTINGS
        </Container>
    );
}

export default Settings;
