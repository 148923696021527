import React, { useEffect, useState } from 'react';
import {
    AdminCardContent,
    AdminCardSection,
    AdminCardImage,
    AdminCardTitle,
    AdminCardDesc,
    ExpandDetails,
    AdminCardDetail,
} from './styles.css'
import {
	SwitchContainer
} from './Post/styles.css'
import CustomSwitch from "../Member/CustomSwitch"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import { ReportingAPI, ReportingDB } from '../../../../types';
import defaultProfileImg from '../../../assets/default-user-avatar.png';
import { updateUser } from 'services/cariclub';

interface Props {
    admin: ReportingAPI.MemberInsight;
    viewMode: number;
    appState: ReportingAPI.PrimaryCompanyData | undefined;
    setApplicationState: React.Dispatch<React.SetStateAction<ReportingAPI.PrimaryCompanyData | undefined>>;
}

function AdminCard({ admin, viewMode, appState, setApplicationState }: Props) {

    const [localAdmin, setlocalAdmin] = useState(admin)
    const [toggleExpand, setToggleExpand] = useState(false)

    const cc_token: any = localStorage.getItem('cc_token');
    let _cc_token = null;
    if (cc_token !== null) {
        _cc_token = JSON.parse(cc_token);
    }
    function formatDate(date: string){
        if(date) {
            const datePart = date.slice(0, 10).split("-");
            const timePart = date.slice(11, 19).split(":");
            return `${datePart[1]}/${datePart[2]}/${datePart[0]} ${timePart[0]}:${timePart[1]}:${timePart[2]}`;
        } else {
            return '';
        }
    }

    useEffect(() => {
        setlocalAdmin(admin);
    }, []);

    function handleDailyChecked(e: any) {
        if (appState && appState.company.org_key) {
            let admin_info = {...admin, update_type: 1, EDaily: e.target.checked ? 1: 0, company_key: appState.company.org_key, member_key: admin.key}
            updateUser(admin_info);
            let members = [...appState.members]
            const index = members.findIndex(member => {
                return member.key === admin.key;
            });
            members[index] = {...admin, EDaily: e.target.checked ? 1: 0}
            let new_appstate = {...appState, members: [...members]}
            setApplicationState(new_appstate);
        }
	}

	function handleWeeklyChecked(e: any) {
		// let org_info = {...orgInfo, EWeekly: e.target.checked ? 1: 0}
		// setOrgInfo(org_info)
		// if (appState && appState.company.org_key) {
		// 	updateCompanyInfo(appState.company.org_key, org_info);
		// }
	}

	function handleMonthlyChecked(e: any) {
		// let org_info = {...orgInfo, EMonthly: e.target.checked ? 1: 0}
		// setOrgInfo(org_info)
		// if (appState && appState.company.org_key) {
		// 	updateCompanyInfo(appState.company.org_key, org_info);
		// }
	}

    return (
        <AdminCardContent>
            <AdminCardSection style={{display: 'flex'}}>
                <div>
                    <AdminCardTitle>
                        {admin.first_name} {admin.last_name}
                    </AdminCardTitle>
                    <AdminCardDesc>
                        {admin.organization || ''} {`${admin.permissions}`}
                    </AdminCardDesc>
                </div>
                <div style={{marginLeft:"auto"}}>
                    <AdminCardImage
                        src={admin.profile_url && !admin.profile_url.includes('media.licdn')
                            ? admin.profile_url
                            : defaultProfileImg}
                        alt="profile-image" />
                </div>
            </AdminCardSection>
            {
                toggleExpand ? (
                    <AdminCardDetail style={{height: '100%', padding: '15px'}}>
                        {
                            (_cc_token !== null && ((_cc_token.type == 'Internal' && viewMode == 0) || _cc_token.member_key == admin.key)) && 
                            (
                                <>
                                    <AdminCardTitle>Email Notification Setting</AdminCardTitle>
                                    <div style={{marginTop: '15px'}}>
                                        <SwitchContainer>
                                            <p style={{width: '100px'}}>
                                                Daily
                                            </p>
                                            <CustomSwitch setIsChecked={handleDailyChecked} isChecked={admin.EDaily == 1? true: false} />
                                        </SwitchContainer>
                                    </div>
                                    <div style={{marginTop: '15px'}}>
                                        <SwitchContainer>
                                            <p style={{width: '100px'}}>
                                                Weekly
                                            </p>
                                            <CustomSwitch setIsChecked={handleWeeklyChecked} isChecked={admin.EWeekly == 1? true: false} />
                                        </SwitchContainer>
                                    </div>
                                    <div style={{marginTop: '15px'}}>
                                        <SwitchContainer>
                                            <p style={{width: '100px'}}>
                                                Monthly
                                            </p>
                                            <CustomSwitch setIsChecked={handleMonthlyChecked} isChecked={admin.EMonthly == 1? true: false} />
                                        </SwitchContainer>
                                    </div>
                                </>
                            )
                        }
                    </AdminCardDetail>   
                ) : <></>
            }
            {
            !toggleExpand &&
            <ExpandDetails isActive={false} onClick={() => setToggleExpand(!toggleExpand)}><ExpandMoreIcon /></ExpandDetails>
            }
            {
            toggleExpand &&
            <ExpandDetails isActive={false} onClick={() => setToggleExpand(!toggleExpand)}><ExpandLessIcon /></ExpandDetails>
            }
        </AdminCardContent>
    );
}

export default AdminCard;
