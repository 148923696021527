import React, { useState } from 'react';
import { InsightsApp } from 'global';
import { ReactComponent as Filter } from '../../assets/filter.svg';
import { ReactComponent as Private } from '../../assets/private.svg';
import { ReactComponent as Globe } from '../../assets/globe-public.svg';
import { ReactComponent as Invisible } from '../../assets/invisible.svg';

import { CircularProgress } from '@material-ui/core';

import {
  AdvancedFiltersContainer,
  FilterContainer,
  FilterParent,
  FilterChildrenContainer,
  SearchItem,
} from 'components/Pages/Engagement/styles.css';

import {
  FilterItem,
  FiltersContainer,
  ItemLabel,
} from 'components/DashboardHeader/styles.css';

export type currentMemberType = InsightsApp.MemberPage;

interface IProps {
  handleSearch(searchkey: React.KeyboardEvent<HTMLInputElement>): void;
  openMemberFilters: boolean;
  handleFilterOpen(): void;
  advancedFilters: any;
  handleFilterSelection(event: React.SyntheticEvent, target: string, parent: string, is_cohort?: boolean): void;
  cohortTypeStats: any;
  handleAllClick(): void;
  type: number;
}

const FilterPopup = ({
  handleSearch,
  openMemberFilters,
  handleFilterOpen,
  advancedFilters,
  handleFilterSelection,
  cohortTypeStats,
  handleAllClick,
  type
}: IProps) => {
  const [searchTmpKey, setSearchTmpKey] = useState('');
  const [searchIsLoading, setSearchIsLoading] = useState<boolean>(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const key: string = (event.target as HTMLInputElement).value;
		setSearchTmpKey(key);
	};

  const ctypes = ['CariClub', 'Universal', 'Global'];

  return (
    <FiltersContainer>
      <div style={{ position: 'relative' }}>
        <SearchItem>
          <div>
            <input
              type="text"
              value={searchTmpKey}
              onChange={handleSearchChange}
              onKeyUp={(e) => handleSearch(e)}
              placeholder="Search..."
            />{' '}
          </div>

          {searchIsLoading && (
            <div className="loader">
              <CircularProgress color="inherit" size={15} />
            </div>
          )}
          {!searchIsLoading && (
            <ItemLabel onClick={handleFilterOpen}>
              <Filter id="filter_icon" />
            </ItemLabel>
          )}
        </SearchItem>

        {openMemberFilters && !searchIsLoading && (
          <AdvancedFiltersContainer id="advanced_filter_container">
            {Object.keys(advancedFilters).map((filter, id) => {
              return (
                <FilterContainer key={id}>
                  <FilterParent>
                    <input
                      type="checkbox"
                      id={`parent_${id}`}
                      onChange={(event: React.SyntheticEvent) =>
                        handleFilterSelection(
                          event,
                          '',
                          filter,
                          filter !== 'allocation' && filter !== 'employment'
                            ? true
                            : false,
                        )
                      }
                      checked={
                        Object.keys(advancedFilters[filter]).filter(
                          (key) => advancedFilters[filter][key] === true,
                        ).length > 0
                      }
                    />
                    <span>
                      <span>{filter} (All)</span>
                      {(cohortTypeStats[filter] &&
                        cohortTypeStats[filter].primary && (
                          <span
                            style={{
                              color: '#f5f5f7',
                              background: '#49516d',
                              padding: '3px',
                              borderRadius: '4px',
                              fontSize: '14px',
                              display: 'flex',
                              maxWidth: '60px',
                            }}
                          >
                            primary
                          </span>
                        )) || <></>}
                      {cohortTypeStats[filter] ? (
                        ctypes[cohortTypeStats[filter].type] === 'Public' ? (
                          <span className="public-icon">
                            <Globe />
                          </span>
                        ) : ctypes[cohortTypeStats[filter].type] ===
                          'Private' ? (
                          <span className="private-icon">
                            <Private />
                          </span>
                        ) : ctypes[cohortTypeStats[filter].type] ===
                          'Cariclub' ? (
                          <span className="cariclub-icon">
                            <Invisible />
                          </span>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </span>
                  </FilterParent>
                  <FilterChildrenContainer>
                    {Object.keys(advancedFilters[filter]).map((children, _id) => (
                      <label key={_id}>
                        <input
                          type="checkbox"
                          id={`child_${_id}`}
                          onChange={(event: React.SyntheticEvent) =>
                            handleFilterSelection(
                              event,
                              children,
                              filter,
                              filter !== 'allocation' &&
                                filter !== 'employment'
                                ? true
                                : false,
                            )
                          }
                          checked={advancedFilters[filter][children]}
                        />
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              display: 'flex',
                              flex: '1',
                            }}
                          >
                            {children}
                          </span>{' '}
                        </span>
                      </label>
                    ))}
                  </FilterChildrenContainer>
                </FilterContainer>
              );
            })}
          </AdvancedFiltersContainer>
        )}
      </div>

      <FilterItem onClick={handleAllClick}>
        {type == 1 ?
          <ItemLabel>
            {localStorage.getItem('all_click_tracker--engagement') === 'true'
              ? 'Select All'
              : !localStorage.getItem('all_click_tracker--engagement')
                ? 'Select All'
                : 'Unselect All'}
          </ItemLabel>:
          <ItemLabel>
            {localStorage.getItem('all_click_tracker--pipeline') === 'true'
              ? 'Select All'
              : !localStorage.getItem('all_click_tracker--pipeline')
                ? 'Select All'
                : 'Unselect All'}
          </ItemLabel>
        }
      </FilterItem>
    </FiltersContainer>
  )
}

export default FilterPopup;