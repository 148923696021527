import React, { useEffect, useState, useMemo } from 'react';
import { Formik, Field, Form, FormikHelpers, useFormik } from 'formik';
import { TextField, CircularProgress, MenuItem, Checkbox, Paper, MenuList, ListSubheader, AppBar, Toolbar, IconButton, Typography, InputLabel } from '@material-ui/core';
import { Autocomplete, Alert } from '@material-ui/lab';

import { Tooltip } from '@material-ui/core';
import {
    Container,
    FormContent,
    Formtitle,
    StyledForm,
    FullWidthFormItem,
    FormItem,
    BackButton,
    StyledButton,
    FormGroup,
    MenuItems,
    MenuHeaderContainer,
    DetailsContainer,
    MemberInfosContainer,
    StyledButtons,
    Containers,
    ProfileContainerExpand,
    ProfileContainerExpands,
} from './styles.css';
import Dialog from '@material-ui/core/Dialog';
import { ReactComponent as Back } from '../../../assets/backArrow.svg';
import { RequestMemberSchema } from './validation.schema';
import { RequestMemberType } from './validation.types';
import { ReportingAPI, ReportingDB } from '../../../../types';
import {
    getRequestedMembers,
    requestReviewSubmit,
	getDepartmentByCompany,
	getGroups,
} from '../../../services/cariclub';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import CCLogo from '../../../assets/CCLogo.png';
import config from '../../../config';
import Slide from '@material-ui/core/Slide';

import moment from 'moment';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';

interface Location {
    city: string;
    state: string;
    type: string;
    city_key: string;
    org_key: string;
    is_current: number;
}

interface Company {
    org_key: string;
    org_name: string;
    org_logo: string;
    locations: Location[];
}

interface Props {
    companyId: string | null;
    companies: Company[];
    viewMode: number;
    memberId: string | null;
    setMoreId: (moreId: string) => void;
    reqMembers: never[],
    setReqMembers: (reqMembers: never[]) => void;
}

interface Department {
	name: string;
	department_key: string;
}

interface GroupObj {
	id: number;
	name: string;
	type_id: number;
	type_name: string;
	status: number;
}

interface RequetedMember {
    key: string;
    first_name: string;
    last_name: string;
    organization: string;
    city: string;
    state: string;
    personal_email: string;
    work_email: string;
    title: string;
    matching_status: string;
    person_type: string;
    requested: string;
    department_key: string;
    groups: string;
}
// Review access request
// Admin_ReviewAccessRequest

function MemberRequest({ companyId, companies, viewMode, memberId, setMoreId, reqMembers, setReqMembers }: Props) {
    const [companyInfo, setCompanyInfo] = useState<Company>();
    const [chapters, setChapters] = useState<Location[]>([]);
    const [submitError, setSubmitError] = useState('');
    const [requestedMember, setRequestedMember] = useState<RequetedMember>();
	const [departments, setDepartments] = useState<Department[]>([]);
	const [groups, setGroups] = useState<GroupObj[]>([]);
	const [groupsArray, setGroupsArray] = useState<any>({});

    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            employer: '',
            first_name: requestedMember?.first_name || '',
            last_name: requestedMember?.last_name || '',
            status: '',
            role: requestedMember?.title || '',
            work_email: requestedMember?.work_email || '',
            city: requestedMember?.city,
            department: requestedMember?.department_key || '',
            groups: requestedMember?.groups ? JSON.parse(requestedMember?.groups) : [],
        },
        enableReinitialize: true,
        validationSchema: RequestMemberSchema,
        onSubmit: (values: RequestMemberType) => {
            handleSubmit(values);
        },
    });

    const fetchDepartment = async (companyId: string) => {
		let res = await getDepartmentByCompany(companyId);
		if (res && res.length > 0) {
			setDepartments(res);
			if (requestedMember && requestedMember.department_key) {
				formik.setFieldValue('department', requestedMember.department_key);
			}
		}
	};

    const fetchGroups = async (companyId: string, vmode: number) => {
		let res = await getGroups(companyId, vmode, 1);
		let _temp: any = {};
		if (res && res.length) {
			res.map((group: GroupObj) => {
				if (_temp[`${group.type_name}`]) {
					_temp[`${group.type_name}`][`${group.name}`] = group.id;
				} else {
					_temp[`${group.type_name}`] = {};
					_temp[`${group.type_name}`][`${group.name}`] = group.id;
				}
			});
		}
		setGroups(res || []);
		setGroupsArray({ ..._temp });
	};

    const handleGroupsSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
		let selected = e.target.value as number[];
		const index = selected.indexOf(0);
		if (index > -1) {
			// only splice array when item is found
			selected.splice(index, 1); // 2nd parameter means remove one item only
		}
		formik.setFieldValue('groups', selected);
	};

    useEffect(() => {

    }, []);

    useEffect(() => {
        if (companyId) {
            if (companyId) {
                fetchGroups(companyId, viewMode);
                fetchDepartment(companyId);
            }

            if (companies.length > 0) {
                let company_info: Company = companies.filter((e: any) => e.org_key == companyId)[0];
                let chapters = company_info.locations;
                setCompanyInfo(company_info);
                setChapters(chapters);
            }
        }

        const getRequestedMember = async (companyId: any, memberId: any) => {
            const reqRes: any = await getRequestedMembers(companyId);
            const mem = reqRes.requestedMembers.filter((tempMem: any) => tempMem.key == memberId)[0];
            setRequestedMember(mem);
        }

        getRequestedMember(companyId, memberId);
    }, [companyId, memberId, companies, viewMode]);

    const handleSubmit = async (values: RequestMemberType) => {

        // let perms = [...permissions].filter((e) => !!e.org_key )
        swal({
            title: 'Are you sure?',
            text: '',
            icon: 'warning',
            buttons: [true, 'Confirm'],
        }).then(async (sure) => {
            if (sure) {
                // get chapter locationId (city_key)
                const selectedChapter = chapters.filter((tempChapter: any) => tempChapter.org_key == values.employer)[0];
                const location_key = selectedChapter.city_key;

                let form_value = {
                    first_name: values.first_name,
                    last_name: values.last_name,
                    work_email: values.work_email,
                    role: values.role,
                    status: values.status,
                    org_key: values.employer,
                    member_key: memberId,
                    location_key: location_key,
                    department: values.department,
                    groups: JSON.stringify(values.groups)
                };

                let res = await requestReviewSubmit(form_value);
                if (res.code !== 'c16') {
                    setSubmitError(res.message);
                    return false;
                }
                swal('Success!', '', 'success').then((value) => {
                    let memberFilter = reqMembers.filter((reqMember: any) => { return reqMember.key != memberId })
                    setReqMembers(memberFilter)
                    // history.push(`/engagement/${companyId}`);
                });
            } else {
                return false;
            }
        });
    };

    return (
        <Containers>
            <Paper style={{ width: "100%", boxShadow: "none" }}>
                <FormContent style={{ width: "100%", padding: '25px' }}>
                    {requestedMember?.requested && (
                        <p>{`Submitted on ${moment(requestedMember?.requested).format('MMMM Do YYYY, h:mm:ss a')}`}</p>
                    )}
                    {submitError && <Alert severity="error">{submitError}</Alert>}
                    <form onSubmit={formik.handleSubmit} className="add-new-member-form">
                        <StyledForm style={{ marginTop: 35 }}>
                            <FullWidthFormItem>
                                <InputLabel shrink>*Name and office location of corporate sponsor</InputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="employer"
                                    name="employer"
                                    label=""
                                    error={formik.errors.employer && formik.touched.employer ? true : false}
                                    helperText={formik.errors.employer}
                                    value={formik.values.employer}
                                    onChange={formik.handleChange}
                                    select
                                >
                                    <MenuItem value="">Select Chapter</MenuItem>
                                    {chapters.length &&
                                        chapters.map((location) => (
                                            <MenuItem key={location.org_key} value={location.org_key}>
                                                {companyInfo?.org_name} - {location.city}, {location.state}
                                            </MenuItem>
                                        ))}
                                </TextField>
                            </FullWidthFormItem>

                            <FormGroup>
                                <FormItem>
                                    <InputLabel shrink>First Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="first_name"
                                        name="first_name"
                                        label=""
                                        error={formik.errors.first_name && formik.touched.first_name ? true : false}
                                        helperText={formik.errors.first_name}
                                        value={formik.values.first_name}
                                        onChange={formik.handleChange}
                                    />
                                </FormItem>

                                <FormItem>
                                    <InputLabel shrink>Last Name</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="last_name"
                                        name="last_name"
                                        label=""
                                        error={formik.errors.last_name && formik.touched.last_name ? true : false}
                                        helperText={formik.errors.last_name}
                                        value={formik.values.last_name}
                                        onChange={formik.handleChange}
                                    />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <InputLabel shrink>Primary Email</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="work_email"
                                        name="work_email"
                                        label=""
                                        error={formik.errors.work_email && formik.touched.work_email ? true : false}
                                        helperText={formik.errors.work_email}
                                        value={formik.values.work_email}
                                        onChange={formik.handleChange}
                                    />
                                </FormItem>
                                <FormItem>
                                    <InputLabel shrink>Role</InputLabel>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="role"
                                        name="role"
                                        label=""
                                        error={formik.errors.role && formik.touched.role ? true : false}
                                        helperText={formik.errors.role}
                                        value={formik.values.role}
                                        onChange={formik.handleChange}
                                    />
                                </FormItem>
                            </FormGroup>
                            <FormGroup>
                                <FormItem>
                                    <InputLabel shrink>Department</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="department"
                                        name="department"
                                        variant="outlined"
                                        label=""
                                        error={formik.errors.department && formik.touched.department ? true : false}
                                        helperText={formik.errors.department}
                                        value={formik.values.department}
                                        onChange={formik.handleChange}
                                        select
                                    >
                                        <MenuItem value="">Select Department</MenuItem>
                                        {departments.length &&
                                            departments.map((option) => (
                                                <MenuItem style={{ color: '#0e0e0e' }} key={option.department_key} value={option.department_key}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                </FormItem>
                                <FormItem>
                                    <InputLabel shrink>Cohort Tags</InputLabel>
                                    <TextField
                                        fullWidth
                                        id="group"
                                        name="Group"
                                        variant="outlined"
                                        label=""
                                        // error={formik.errors.department && formik.touched.department ? true : false}
                                        // helperText={formik.errors.department}
                                        value={formik.values.groups}
                                        onChange={handleGroupsSelect}
                                        select
                                        SelectProps={{
                                            multiple: true,
                                            renderValue: (selected) =>
                                                (selected as number[])
                                                    .filter((id: number) => groups.find((x) => x.id == id))
                                                    .map((id: number) => groups.find((x) => x.id == id)?.name || id)
                                                    .join(', '),
                                        }}
                                    >
                                        <MenuItem value={0} style={{ color: '#0e0e0e' }}>Select Group</MenuItem>
                                        {Object.keys(groupsArray).map((parent) => {
                                            return [
                                                <ListSubheader
                                                    style={{
                                                        width: '100%',
                                                        background: '#f2f2f2',
                                                        padding: 12,
                                                        fontWeight: 500,
                                                        color: '#1d1d1d',
                                                        fontSize: 16,
                                                    }}
                                                >
                                                    {parent}
                                                </ListSubheader>,
                                                Object.keys(groupsArray[parent]).map((child: any) => {
                                                    return (
                                                        <MenuItem
                                                            key={`group_${groupsArray[parent][child]}`}
                                                            value={groupsArray[parent][child] as number}
                                                            role="option"
                                                        >
                                                            <Checkbox
                                                                checked={
                                                                    formik.values.groups.indexOf(
                                                                        Number(groupsArray[parent][child]),
                                                                    ) > -1
                                                                }
                                                            />
                                                            {child}
                                                        </MenuItem>
                                                    );
                                                }),
                                            ];
                                        })}
                                    </TextField>
                                </FormItem>
                            </FormGroup>
                            <FormItem>
                                <InputLabel shrink>Account Status</InputLabel>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="status"
                                    name="status"
                                    label=""
                                    error={formik.errors.status && formik.touched.status ? true : false}
                                    helperText={formik.errors.status}
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    select
                                >
                                    <MenuItem value="">Select Status</MenuItem>

                                    <MenuItem key='Active' value='Active'>
                                        <Tooltip title="Approve request and send invitation email">
                                            <span>Assign CariClub License</span>
                                        </Tooltip>
                                    </MenuItem>

                                    <MenuItem key='Waitlisted' value='Waitlisted'>
                                        <Tooltip title="Employee is eligible, add to CariClub License Waitlist">
                                            <span>Add to License Waitlist</span>
                                        </Tooltip>
                                    </MenuItem>

                                    <MenuItem key='Inactive' value='Deactivated'>
                                        <Tooltip title="Employee is currently ineligible for CariClub License">
                                            <span>Move to Request Backlog</span>
                                        </Tooltip>
                                    </MenuItem>
                                </TextField>
                            </FormItem>
                        </StyledForm>
                        <StyledForm style={{ justifyContent: 'center', marginTop: 15 }}>
                            <FormGroup>
                                <StyledButtons isSubmit type="submit">
                                    Confirm
                                </StyledButtons>
                                <StyledButtons isCancel type="button" onClick={() => setMoreId('')}>
                                    Cancel
                                </StyledButtons>
                            </FormGroup>
                        </StyledForm>
                    </form>
                </FormContent>
                <ProfileContainerExpands onClick={() => setMoreId('')}>
                    <ExpandLess />
                </ProfileContainerExpands>
            </Paper>
        </Containers >
    );
}

export default MemberRequest;
