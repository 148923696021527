import React, { useState, useEffect } from 'react';
import {
	Container,
	DetailsContainer,
	DetailsContent,
	PlaceholderImg,
	ProfileImg,
	EyeImg,
	MemberName,
	MemberEmp,
	InterestIcon,
	HistoryContent,
	BackButton,
	ProfileContainer,
	ProfileContainerExpand,
	ImageContainer,
	MemberInfosContainer,
	MemberUpdates,
	SectionHeader,
	SectionHeaderText,
	RightSection,
	RightSectionItem,
	ProgressBarContainer,
	LeftSection,
	LoadMoreContainer,
	StyledButton,
	StatsInfoBox,
	DownloadButton,
	ItemLabel,
	ButtonsRow,
	LicenseStatusCircle,
	Dropdown,
	DropdownMenu,
	Spinner,
	Overlay,
	ProgressSection,
	LicenseLabel,
	OtherContent,
	Detail,
	OtherContents,
	InterestsSection,
	CohortTag,
	ProfileCompletion,
	ProfileHeader,
	ProfileCard,
} from './styles.css';

import { useHistory } from 'react-router-dom';
import { ReactComponent as Back } from '../../../assets/backArrow.svg';
import { ReactComponent } from '../../../assets/edit-icon.svg';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import advocacy from '../../../assets/advocacy.svg';
import aid from '../../../assets/aid.svg';
import community from '../../../assets/community.svg';
import culture from '../../../assets/culture.svg';
import education from '../../../assets/education.svg';
import environment from '../../../assets/environment.svg';
import health from '../../../assets/health.svg';
import recreation from '../../../assets/recreation.svg';
import STEM from '../../../assets/STEM.svg';
import defaultProfileImg from '../../../assets/default-user-avatar.png';
import eyeImg from '../../../assets/eyeicon.png';

import viewNetwork from '../../../assets/view_network.png';
import followNonprofit from '../../../assets/follow_nonprofit.png';
import shareNonprofit from '../../../assets/share_nonprofit.png';
import viewCompany from '../../../assets/view_company.png';
import discNonprofit from '../../../assets/disc_nonprofit.png';
import addExperience from '../../../assets/add_exp.png';
import addQuote from '../../../assets/add_quote.png';
import addBio from '../../../assets/add_bio.png';
import createAccount from '../../../assets/create_account.png';
import applyPosition from '../../../assets/apply_position.png';

import { ReactComponent as LinkedIn } from '../../../assets/LinkedInLogo.svg';
import Mail from '../../../assets/env.png';
import { ReactComponent as ExternalLink } from '../../../assets/ExternalLink.svg';
import CariClub_Admin from '../../../assets/CariClub_Admin.png';
import CariClub_Blue from '../../../assets/CariClub_Blue.png';
import config from '../../../config';
import moment from 'moment';
import swal from 'sweetalert';

import { H1, P } from '../../Common/styles.css';

import { PrimeStatus, ActiveStatus, InactiveStatus, IdleStatus, RightSectionTopItem, CopperButton } from './styles.css';

import { InsightsApp } from 'global';
import { ReportingDB, ReportingAPI } from '../../../../types';
import MemberHistory from './MemberHistory';
import Application from './Application';
import { getApplications, getAdminLogs, getEngagementStatuses, getMemberStats, updateMemberStatus, 
	getCariclubRoles,
	getCandidateLicenses, getLicenseTypes, getLicenseTerms, getUserData,
	getGroups } from '../../../services/cariclub';
import { EngagementStatuses } from '../Engagement/types';
import ProgressBar from './ProgressBar';
import Post from './Post';
import EditMemberProfile from './EditMemeber';
import { Tooltip } from '@material-ui/core';
import { getMemberTooltip } from '../helper';
import { ReactComponent as Checkmark } from '../../../assets/checkmark-icon.svg';
import { ReactComponent as DropdownIcon } from '../../../assets/dropdown.svg';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import RemoveIcon from '@material-ui/icons/Remove';
import { LocalBar } from '@material-ui/icons';

import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface Location {
	city: string;
	state: string;
	type: string;
	city_key: string;
	org_key: string;
	is_current: number;
}

interface Company {
	org_key: string;
	org_name: string;
	org_logo: string;
	locations: Location[];
}

interface Props {
	userData: any;
	member: InsightsApp.MemberPage | undefined;
	plStatuses: any;
	atsStatuses: any;
	milestoneMarkers: any;
	queryRoute: string | null;
	queryCompanyID: string | null;
	queryMemberKey: string | null;
	userAuthStatus: string;
	isAuth: (userAuthStatus: string, threshold: string) => boolean;
	viewMode: number;
	companyLogo: string;
	companyName: string | null;
	initDataLoad(companyID: string, companyName: string, reload: boolean): void;
	companies: Company[];
	cclogic: any
}

interface ILog {
	action: string;
	date: string;
	actor: { key: string; name: string };
	is_public: number;
	note: string;
}
interface licenseObj {
	id: number;
	name: string;
	forQ?: number | null;
}

const CAUSE_ICON_MAP = {
	Advocacy: advocacy,
	Aid: aid,
	Community: community,
	Culture: culture,
	Education: education,
	Environment: environment,
	Health: health,
	Recreation: recreation,
	STEM: STEM,
};

interface userStats {
	activatedDate: string | null;
	department: string | null;
	employer: string;
	invitationDate: string | null;
	lastLogin: string | null;
	license: string;
	location: string | null;
	personalEmail: string | null;
	personalPhone: string | null;
	profileCompletion: number;
	puaseDates: string | null;
	workEmail: string | null;
	workPhone: string | null;
}
interface GroupObj {
	id: number;
	name: string;
	type_id: number;
	type_name: string;
	status: number;
}

const Transition:any = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>,
) {
	return <Slide direction="left" ref={ref} {...props} />;
});

function Member({
	member,
	plStatuses,
	atsStatuses,
	milestoneMarkers,
	queryRoute,
	queryCompanyID,
	queryMemberKey,
	userAuthStatus,
	isAuth,
	viewMode,
	companyLogo,
	userData,
	companies,
	companyName,
	initDataLoad,
	cclogic
}: Props) {
	const history = useHistory();

	const [engagementState, setEngagementState] = useState<EngagementStatuses[]>([]);
	const [applications, setApplications] = useState([]);
	const [logs, setLogs] = useState<any[]>([]);
	const [filterLogs, setFilterLogs] = useState<any[]>([]);
	const [page, setPage] = useState<number>(1);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loadMore, setLoadMore] = useState(false);
	const [memberStats, setMemberStats] = useState<userStats>();
	const [showEditForm, setShowEditForm] = useState(false);
	const [hideButton, setHideButton] = useState<boolean>(false);
	const [applicationFilters, setApplicationFilters] = useState<any[]>([]);
	const [cariclubRoles, setCariclubRoles] = useState<licenseObj[]>([]);
	const [licenses, setLicenses] = useState<licenseObj[]>([]);
	const [licenseTerms, setLicenseTerms] = useState<licenseObj[]>([]);
	const [licenseTypes, setLicenseTypes] = useState<licenseObj[]>([]);
	const [memberData, setMemberData] = useState<any>({});
	
	const [groups, setGroups] = useState<GroupObj[]>([]);
	const [groupsArray, setGroupsArray] = useState<any>({});

	const [cardFilter, setCardFilter] = useState(false)
	const [toggleMMMenu, setToggleMMMenu] = useState(false)
	const [memberMM, setMemberMM] = useState(0)

	const [open, setOpen] = React.useState(true);

	const handleClick = () => {
		setShowEditForm(!showEditForm);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		history.push(`/${queryRoute}/${queryCompanyID}`,{
			member_key:history?.location?.state?.recordId
		});
		setOpen(false);
	};

	const cc_token: any = localStorage.getItem('cc_token');
	let _cc_token = null;
	if (cc_token !== null) {
		_cc_token = JSON.parse(cc_token);
	}
	function parseDate(date: string) {
		return new Date(date);
	}

	const getStausEle = (status: number | null) => {
		if (status !== null) {
			const statusString = engagementState[(engagementState[status - 1]?.parent_id || status) - 1]?.name;
			if (statusString === 'Prime') return <PrimeStatus>{statusString}</PrimeStatus>;
			else if (statusString === 'Active') return <ActiveStatus>{statusString}</ActiveStatus>;
			else if (statusString === 'Idle') return <IdleStatus>{statusString}</IdleStatus>;
			else if (statusString === 'Inactive') return <InactiveStatus>{statusString}</InactiveStatus>;
		}
	};

	const fetchInit = async () => {
		if (member) {
			fetchCariclubRoles();
			fetchCandidateLicenses();
			fetchLicenseTerms();
			fetchLicenseTypes();

			fetchMemberInfo(member.key);

			if(queryCompanyID) {
				
			console.log("member group=> ", member.groups ? JSON.parse(member.groups) : [])
				fetchGroups(queryCompanyID, viewMode);
			}
			
			setIsLoading(true);
			let res = await getMemberStats(member.key);
			setMemberStats(res);
			setApplications(await getApplications(member.key));
			let results = await getAdminLogs(undefined, member.key, viewMode, applicationFilters.length ? 6 : undefined, applicationFilters);

			setLogs(results);
			if (results.length == 5) {
				//page_size 5
				setLoadMore(true);
			}
			setMemberMM(member.userpipeline_status)
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchInit();
	}, [member, viewMode]);

	const fetchAdminLogs = async () => {
		if (member) {
			setIsLoading(true);
			setPage(1);
			setLoadMore(true);
			setHideButton(false);

			let results = await getAdminLogs(undefined, member.key, viewMode, applicationFilters.length ? 6 : undefined, applicationFilters);

			setLogs(results);
			if (results.length == 5) {
				//page_size 5
				setLoadMore(true);
				setHideButton(false);
			}
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchAdminLogs();
	}, [applicationFilters]);

	const loadMoreNoti = async () => {
		setIsLoading(true);
		let results = await getAdminLogs(undefined, member?.key, viewMode, applicationFilters.length ? 6 : undefined, applicationFilters, page + 1, 5);
		if (results.length == 5) {
			setLoadMore(true);
			setPage(page + 1);
			setHideButton(false);
		} else if (results.length < 5) {
			setHideButton(true);
		}
		let new_logs = [...logs, ...results];
		setLogs(new_logs);
		setIsLoading(false);
	};

	useEffect(() => {
		getEngagementStatuses()
			.then((res) => {
				setEngagementState(res);
			})
			.catch((error) => {
				console.log({ engagementStateErr: error.response.data });
			});
	}, []);

	const steps = [
		'Deactivating',
		'Dismissing',
		'Suspending',
		'Idling',
		'Pausing',
		'Pending',
		'Activating',
		'Matching',
		'Consulting',
	];

	const handleImgLoadError = (e: any) => {
		// return <ProfileImg src={defaultProfileImg} style={{ width: "200px" }} />
		e.target.src = defaultProfileImg;
		e.target.onerror = null;
	};

	const onSave = () => {
		if (queryCompanyID) {
			initDataLoad(queryCompanyID, companyName || '', true);
			setShowEditForm(false);
		} else {
			swal('Something went wrong!', '', 'error').then((value) => {
				setShowEditForm(false);
			});
		}
	};

	const onCancel = () => {
		setShowEditForm(false);
	};

	const clientViewAvailable = (member: any) => {
		return ((member.cariclub_role != 3 && member.candidate_license == 4) || ([65, 66, 71].includes(member.userpipeline_status))) ? true : false;
	}

	const filterChanged = (applicationId: any) => {
		console.log(applicationId)
		let temp = [...applicationFilters]
		if (!temp.includes(applicationId)) {
			temp.push(applicationId)
		} else {
			temp.splice(temp.indexOf(applicationId), 1)
		}

		setApplicationFilters(temp)
	}

	const getUserPipelineStatus = (userpipeline_status: number) => {
		let names = {
			'position': '',
			'stage': '',
			'description': '',
			'progress': ''
		}
		let status = milestoneMarkers.filter((el: any) => { return el.id === userpipeline_status; })
		if (status.length > 0) {
			status = status[0];
			names.position = status.name;
			names.description = status.description;
			names.progress = status.progress;

			// check if it has parent stage
			if (status.parent_id) {
				const parent_status = milestoneMarkers.filter((el: any) => { return el.id === status.parent_id; })
				if (parent_status.length > 0) {
					names.stage = parent_status[0].name;
				}
			}
		}
		return names;
	}

	const getAppPipelineStatus = (userpipeline_status: number) => {
		let names = {
			'position': '',
			'stage': '',
			'description': '',
			'pps_id': userpipeline_status,
			'pps_parent_id': null,
			'progress': ''
		}
		let status = plStatuses.filter((el: any) => { return el.id === userpipeline_status; })
		if (status.length > 0) {
			status = status[0];
			names.position = status.name;
			names.description = status.descriptoin;
			names.progress = status.progress;

			// check if it has parent stage
			if (status.parent_id) {
				names.pps_parent_id = status.parent_id
				const parent_status = plStatuses.filter((el: any) => { return el.id === status.parent_id; })
				if (parent_status.length > 0) {
					names.stage = parent_status[0].name;
				}
			}
		}
		return names;
	}

	const updateMemberMM = async (mileston_marker_id: number) => {
		setToggleMMMenu(false)
		swal({
			title: 'Confirm update',
			text: "Are you sure you want to update the Milestone Marker?",
			icon: 'warning',
			buttons: [true, true],
		}).then(async (sure) => {
			if (sure && member) {
				var data = {
					member_key: member.key,
					// ...userStatuses
					UserPriviledgeTerm: member.priviledge_term,
					UserPriviledgeType: member.priviledge_type,
					CariclubRole: member.cariclub_role,
					CandidateLicense: member.candidate_license,
					UserPipelineStatus: mileston_marker_id
				}
				await updateMemberStatus(data)
				setMemberMM(mileston_marker_id)
			} else {
				return false;
			}
		})
	}

	const customSort = (apps: any) => {
		// sort members by app tracker progress
		const mySort = (a: ReportingAPI.ApplicationInsight, b: ReportingAPI.ApplicationInsight) => {
			const a_progress = getAppPipelineStatus(a.pipelinestatus_id).progress ? getAppPipelineStatus(a.pipelinestatus_id).progress : 0
			const b_progress = getAppPipelineStatus(b.pipelinestatus_id).progress ? getAppPipelineStatus(b.pipelinestatus_id).progress : 0
			if (a_progress < b_progress) {
				return 1;
			}
			if (a_progress > b_progress) {
				return -1;
			}
			return 0;
		}

		apps.sort(mySort)
		console.log('sort here->', apps)
		return apps
	}

	const fetchCariclubRoles = async () => {
		let res = await getCariclubRoles();
		console.log('CRs => ', res);
		setCariclubRoles(res);
	};

	const fetchCandidateLicenses = async () => {
		let res = await getCandidateLicenses();
		console.log('CLs =>', res);
		setLicenses(res);
	};

	const fetchLicenseTerms = async () => {
		let res = await getLicenseTerms();
		console.log('terms =>', res);
		setLicenseTerms(res);
	};
	const fetchLicenseTypes = async () => {
		let res = await getLicenseTypes();
		console.log('types =>', res);
		setLicenseTypes(res);
	};
	const fetchMemberInfo = async (memberKey: string) => {
		let res = await getUserData(memberKey);
		setMemberData(res);
	}
	const fetchGroups = async (companyId: string, vmode: number) => {
		let res = await getGroups(companyId, vmode, 1);
		let _temp: any = {};
		if (res && res.length) {
			res.map((group: GroupObj) => {
				if (_temp[`${group.type_name}`]) {
					_temp[`${group.type_name}`][`${group.name}`] = group.id;
				} else {
					_temp[`${group.type_name}`] = {};
					_temp[`${group.type_name}`][`${group.name}`] = group.id;
				}
			});
		}
		setGroups(res || []);
		setGroupsArray({ ..._temp });
		console.log('Groups ===>', res)
		console.log('Groups Arr ===>', _temp)
	};
	return (
		<Container>
			<BackButton to={`/${queryRoute}/${queryCompanyID}`}>
				<a>
					<Back /> Back
				</a>
			</BackButton>
			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
				style={{ paddingLeft: "180px" }}
			>
				{toggleMMMenu && (
					<Overlay
						onClick={() => {
							setToggleMMMenu(false);
						}}
					></Overlay>
				)}
				<AppBar style={{ position: 'sticky', background: '#FDBD41' }}>
					<Toolbar style={{ height: '72px' }}>
						<IconButton
							edge="start"
							color="inherit"
							onClick={handleClose}
							aria-label="close"
						>
							<CloseIcon />
						</IconButton>
						<Typography style={{ marginLeft: 2, flex: 1 }} variant="h6" component="div">
							Member Profile
						</Typography>
					</Toolbar>
				</AppBar>
				{member && (
					<MemberInfosContainer style={{ padding: "30px" }}>
						<LeftSection>

							<ProfileContainer style={{ border: '1px solid #dfe0eb' }}>
								{member && (
									<>
										<DetailsContainer>
											<ImageContainer>
												<ProfileImg
													src={
														member.profile_url && !member.profile_url.includes('media.licdn')
															? member.profile_url
															: defaultProfileImg
													}
													alt="profile-image"
												/>
												<Tooltip title={getMemberTooltip(member).title}>
													<LicenseStatusCircle
														shape={getMemberTooltip(member).shape}
														status={getMemberTooltip(member).color}
													/>
												</Tooltip>
											</ImageContainer>

											<DetailsContent>
												<MemberName>
													{member.first_name} {member.last_name}
													{clientViewAvailable(member) && (
														<span>
															<EyeImg src={eyeImg} alt="Not showing on client view"></EyeImg>
														</span>
													)}
													<a href={`mailto:${member.work_email}`} target="_blank">
														<img src={Mail} style={{width: "22px", marginLeft: "5px", borderRadius: "3px"}} />
													</a>
												</MemberName>	
												<MemberEmp>
													{member.organization || ''} - {`${member.city || ''}, ${member.state || ''}`}
												</MemberEmp>
												
												<Dropdown>
													<label onClick={() => setToggleMMMenu(!toggleMMMenu)}
													>{getUserPipelineStatus(memberMM).position ? getUserPipelineStatus(memberMM).position : "Update Milestone Marker"}&nbsp;&nbsp;
														<ExpandMoreIcon /></label>
													{
														toggleMMMenu &&
														milestoneMarkers &&
														<DropdownMenu>
															<ul className="dropdown-menu">
																{
																	milestoneMarkers.filter((m1: any) => m1.parent_id == null).map((mm: any) => (
																		<li key={`cariclub_mm_${mm.name}`}>
																			<span className={getUserPipelineStatus(memberMM).stage == mm.name ? `category-menu active` : `category-menu`}>
																				{mm.name}
																				<DropdownIcon className="DropdownIcon" style={{ transform: 'rotate(-90deg)' }} />
																			</span>
																			<ul className="dropdown-menu dropdown-submenu">
																				{
																					milestoneMarkers.filter(
																						(m2: any) => m2.parent_id == mm.id).map((subMM: any) => ((
																							<li key={`cariclub_mm_${subMM.name}`}
																								onClick={() => updateMemberMM(subMM.id)}
																							>
																								<span>{subMM.name}</span>
																								{
																									memberMM == subMM.id &&
																									<Checkmark />
																								}
																							</li>
																						)))
																				}
																			</ul>
																		</li>
																	))
																}
															</ul>
														</DropdownMenu>
													}
												</Dropdown>

												{
													//</div>
													//<hr />
													
												}
											</DetailsContent>
											<ButtonsRow>
												{/* <Tooltip title="Click to enter member edit mode.">
												<DownloadButton onClick={() => setShowEditForm(true)}>
													<EditIcon style={{ fill: '#FFF' }} />
												</DownloadButton>
												</Tooltip> */}
												<div style={{ textAlign: "center" }}>
													
												</div>
												{viewMode == 0 && (
													<div style={{ textAlign: "center" }}>
														<a href={`${config.cc_admin_url}/members/${member.key}`} target="_blank">
															<img src={CariClub_Admin} alt="cc-logo" width="30px" />
														</a>
													</div>
												)}
												<div style={{ textAlign: "center" }}>
													<a href={`${config.cc_url}/member-profile/${member.key}`} target="_blank">
														<img src={CariClub_Blue} alt="cc-logo" width="30px" />
													</a>
													<Typography style={{ fontSize: "14px", color: "#a9adbb", fontWeight: "500" }}> CariClub</Typography>
												</div>
												{member.linkedin_url && (
													<div style={{ textAlign: "center" }}>
														<a href={`${member.linkedin_url || 'https://linkedin.com'}`} target="_blank">
															<LinkedIn width="30px" />
														</a>
													</div>
												)}
											</ButtonsRow>
											<div style={{ position: 'absolute', top: '5px', right: '10px' }} >
												<div style={{ float: 'right', cursor: 'pointer' }} >
													<EditIcon onClick={handleClick} />
													<Typography style={{ fontSize: "14px", color: "#a9adbb", fontWeight: "500", lineHeight: '10px' }}> Edit</Typography>
												</div>
											</div>
										</DetailsContainer>
										{memberStats && memberStats.license && (
											<>
												<OtherContents>
													{getUserPipelineStatus(memberMM).progress && (
														<Detail style={{ width: '50%' }}>
															<p>{getUserPipelineStatus(memberMM).progress}% milestones achieved</p>
															<span>{getUserPipelineStatus(memberMM).description}</span>
														</Detail>
													)
													}
													<Detail>
														<p>Desired Outcome</p>
														<span> - </span>
													</Detail>
													<Detail>
														<p>Experience Level</p>
														<span> - </span>
													</Detail>
												</OtherContents>
												<OtherContents>
													<Detail>
														<p>CariClub License</p>
														<span> 
															{
																licenses.filter((val) => val.id == member.candidate_license).length ? 
																licenses.filter((val) => val.id == member.candidate_license)[0].name 
																: '-'
															} 
														</span>
													</Detail>
													<Detail>
														<p>CariClub Role</p>
														<span> 
															{
																cariclubRoles.filter((val) => val.id == member.cariclub_role).length ?
																cariclubRoles.filter((val) => val.id == member.cariclub_role)[0].name
																: '-'
															}
														</span>
													</Detail>
													<Detail>
														<p>Sponsor Relationship</p>
														<span> 
															{
																licenseTerms.filter((val) => val.id == member.priviledge_term).length ?
																licenseTerms.filter((val) => val.id == member.priviledge_term)[0].name
																: '-'
															}
														</span>
													</Detail>
													<Detail>
														<p>Q Platform Access</p>
														<span>
															{
																licenseTypes.filter((val) => val.id == member.priviledge_type).length ?
																licenseTypes.filter((val) => val.id == member.priviledge_type)[0].name
																: '-'
															}
														</span>
													</Detail>
												</OtherContents>
												<OtherContents>
													<Detail>
														<p>Cohort Tags</p>
														<p style={{marginTop: "15px"}}>
														{ member.groups && JSON.parse(member.groups).length > 0 ? 
														 JSON.parse(member.groups).map((group: any) => {
															return (
																<CohortTag>{
																	groups.filter( (g) => g.id == group ).length > 0 ?
																	groups.filter( (g) => g.id == group )[0].name : ''
																}</CohortTag>
															)
														 }): '-' 
														}</p>
														
													</Detail>
												</OtherContents>
												
												<ProfileContainerExpand onClick={handleClick}>
													{showEditForm ? <ExpandLess /> : <ExpandMore />}
												</ProfileContainerExpand>
											</>
										)}

									</>
								)}
								{showEditForm && (
									<EditMemberProfile
										member={member}
										parentCompanyID={queryCompanyID}
										companies={companies}
										submit={onSave}
										cancel={onCancel}
										viewMode={viewMode}
									/>
								)}
							</ProfileContainer>

							{/* {getUserPipelineStatus(memberMM).progress && (
								<ProgressSection>
									<span>{getUserPipelineStatus(memberMM).progress}% Milestones achieved</span>
									{getUserPipelineStatus(memberMM).description}
								</ProgressSection>
							)} */}
							<Post
								member={member}
								companyLogo={companyLogo}
								applications={applications}
								fetchLogs={fetchInit}
							/>
							<MemberUpdates>
								<SectionHeader>
									<SectionHeaderText>Member Updates</SectionHeaderText>
								</SectionHeader>
								{!isLoading && logs &&
									logs.length > 0 &&
									logs
										.sort((a: ILog, b: ILog) => {
											if (parseDate(a.date) < parseDate(b.date)) return 1;
											if (parseDate(a.date) > parseDate(b.date)) return -1;

											return 0;
										})
										.map((log, idx: number) => {
											return (
												<MemberHistory
													userData={userData}
													key={`member-history-${idx}`}
													log={log}
													isAuth={isAuth}
													userAuthStatus={userAuthStatus}
													viewMode={viewMode}
													fetchLogs={fetchAdminLogs}
												/>
											);
										})}
								{!isLoading && loadMore && !hideButton && (
									<LoadMoreContainer>
										<StyledButton onClick={loadMoreNoti}>Load more notifications</StyledButton>
									</LoadMoreContainer>
								)}
								{isLoading && <Spinner />}
							</MemberUpdates>
						</LeftSection>
						<RightSection>
							{memberStats && memberStats.license && (
								<div>
									<SectionHeaderText>Activity Data</SectionHeaderText>
									<StatsInfoBox>
										{/* <p>License: {memberStats.license}</p> */}
										<p>
											Invitation Date:{' '}
											{memberStats.invitationDate
												? moment(memberStats.invitationDate).format('MM/DD/YYYY, h:mm a')
												: ''}
										</p>
										<p>
											Activation Date:{' '}
											{memberStats.activatedDate
												? moment(memberStats.activatedDate).format('MM/DD/YYYY, h:mm a')
												: ''}
										</p>
										<p>
											Matched Date:{' '}
											{/* {memberStats.activatedDate
												? moment(memberStats.activatedDate).format('MM/DD/YYYY, h:mm a')
												: ''} */}
										</p>
										{/* <p>
											Profile Completion:{' '}
											{memberStats.profileCompletion == 100
												? 'Completed'
												: `${memberStats.profileCompletion || 0} / 6`}
										</p> */}
										<p>
											Last Login:{' '}
											{memberStats.lastLogin
												? moment(memberStats.lastLogin).format('MM/DD/YYYY, h:mm a')
												: ''}
										</p>
										<p>
											Last Contacted:{' '}
											{logs.filter(l => l.type == "Email Data").length > 0
												? moment(logs.filter(l => l.type == "Email Data")[0].date).format('MM/DD/YYYY, h:mm a')
												: ''}
										</p>
									</StatsInfoBox>
								</div>
							)}
							{memberData && memberData.profile_completion && (
								<ProfileCompletion>
									<ProfileHeader>
										<CircularProgress style={{width:"50px"}} color="primary" variant='determinate' value={memberData.profile_completion.progress} />
										<div style={{marginLeft: "15px"}}>
											<p>Profile Completion</p>
											<label>+80% is ideal</label><br/>
											<span>{memberData.profile_completion.progress}%</span>
											{ cardFilter && (
												<button onClick={() => {
													setCardFilter(!cardFilter);
												}}>Hide Steps <ExpandLessIcon /></button>
											)}
											{ !cardFilter && (
											<button onClick={() => {
													setCardFilter(!cardFilter);
												}}>Show Steps <ExpandMoreIcon /></button>
											)}
										</div>
									</ProfileHeader>
									{ cardFilter && (
										<div>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 0).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 0).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												
												<img src={createAccount} alt="" />Complete Onboarding
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 2).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 2).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={addBio} alt="" />Add Profile Bio
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 3).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 3).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={addQuote} alt="" />Add Profile Quote
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 4).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 4).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={addExperience} alt="" /> Add Philanthropy Experience
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 0).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 0).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={viewNetwork} alt="" />View your network
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 7).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 7).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={viewCompany} alt="" />Visit Company Profile Page
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 5).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 5).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={discNonprofit} alt="" />Visit Discover Page
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 8).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 8).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={shareNonprofit} alt="" />Refer A Nonprofit
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 6).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 6).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={followNonprofit} alt="" />Follow A Nonprofit
											</ProfileCard>
											<ProfileCard style={{ 
												background: memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 9).length == 1 ? 'rgba(210,202,202,0.3)' : ''
											}}>
												{ memberData.profile_completion.completed && memberData.profile_completion.completed.filter((compCard: any) => compCard.card_id == 9).length == 1 ? (
													<DoneIcon style={{ fill:'#rgb(58, 130, 208)', marginTop: '5px' }} />
												) : (
													<RemoveIcon style={{ fill:'lightgray', marginTop: '5px' }} />
												)}
												<img src={applyPosition} alt="" />Submit Opportunity Application
											</ProfileCard>
										</div>
									)}
								</ProfileCompletion>
							)}
							<div>
								<SectionHeaderText>
									Opportunities
								</SectionHeaderText>
								<div>
									{applications &&
										applications.length > 0 &&
										customSort(applications).map(
											(application: ReportingAPI.ApplicationInsight, idx: number) => {
												return (
													<Application
														key={`member-application-${idx}`}
														application={application}
														plStatuses={plStatuses}
														atsStatuses={atsStatuses}
														cclogic={cclogic}
														isAuth={isAuth}
														userAuthStatus={userAuthStatus}
														filterChanged={filterChanged}
														viewMode={viewMode}
													/>
												);
											},
										)}
								</div>
							</div>

							<RightSectionItem>
								<SectionHeaderText>{member.first_name}’s Interests</SectionHeaderText>
								<InterestsSection>

								{ member.interests.map((interest: InsightsApp.Interests, idx: number) => {
										return (
											<div key={`member-interest-${idx}`}>
												<InterestIcon src={CAUSE_ICON_MAP[interest]} />
												<P>{interest}</P>
											</div>
										)
									})
								}
								
								</InterestsSection>
							</RightSectionItem>
						</RightSection>
					</MemberInfosContainer>
				)}
			</Dialog>

		</Container>
	);
}

export default Member;
