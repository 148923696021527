import styled from 'styled-components';

export const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background : #F7F5F9;
    overflow-X: hidden;
`