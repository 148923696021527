import styled from 'styled-components'
import { Button } from '../../Common/styles.css'

export const UploadNotesSection = styled.label`
    
    
    padding: 10px 12px;
    font-weight: 500;
    border-radius: 7px;
    color: white;

    input[type="file"] {
        position: absolute;
        top: -1000px;
    }
`;

export const UploadTrigger = styled.div`
    cursor: pointer;
    margin-left: 10px;
    margin-right: 10px;
    position: relative;
`;

export const DropdownButton = styled.button`
    width: 35px;
    height: 35px;
    font-family: Mulish,sans-serif;
    font-size: 22px;
    cursor: pointer;
    background: #fed600;
    border: 2px white solid;
    font-weight: bolder;
    border-radius: 50%;
    color: white;
`;

export const UploadDropdownSection = styled.div`
    padding: 10px 0;
    background: #49516d;
    position: absolute;
    top: 42px;
    right: -60px;
    font-family: Inter,sans-serif;
    z-index: 333;
    border-radius: 8px;
    color: #f5f5f7;
    cursor: pointer;
    width: 150px;
`;

export const UploadDropdownRow = styled.label`
    display: block;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    
    &:hover {
        background: gray;
    }

    input[type="file"] {
        position: absolute;
        top: -1000px;
    }
`;

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: transparent;
    z-index: 100;
`;

export const Dropdown = styled.ul`
    position: relative;

    .dropdown-menu {
        padding: 5px 0;
        background: #49516d;
        position: absolute;
        top: calc(100% + 10px);
        z-index: 1000;
        font-family: Inter,sans-serif;
        border-radius: 8px;
        color: #f5f5f7;
        cursor: pointer;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        font-weight: 500;

        li {
            position: relative; 
            &:hover {
                background: #7d8499;
            }
            .DropdownIcon {
                stroke: #f5f5f7;
                margin-left: 15px;
            }
        }
        li input[type="file"] {
            position: absolute;
            opacity: 0;
            height: 100%;
            width: 100%;
            cursor: pointer;
        }
        li > span {
            a {
                color: #f5f5f7;
                text-decoration: none;
            }

            white-space: nowrap;
            padding: 15px;
            display: block;
            font-family: 'Inter', sans-serif;
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .lock-icon {
                fill: #f5f5f7;
                stroke: none !important;
                border: none !important;
                position: absolute;
                right: 5px;
                top: 50%;
                transform: translateY(-50%) scale(0.35);
            }
            &:hover {
                background: #7d8499;
            }
        }
    }

    .dropdown-menu .dropdown-submenu {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 1px 8px 0px;
        margin-left: 1px;
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
        z-index: 1000;
    }
    .dropdown-menu .dropdown-submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu > li:hover > .dropdown-submenu {
        display: block;
    }
`;