const config = {
    cognito: { REGION: 'us-east-1', USER_POOL_ID: 'us-east-1_uH5tOdfmz', IDENTITY_POOL_ID: 'us-east-1:87c3d4bc-080e-4082-acc2-e17b38157018', APP_CLIENT_ID: '1vjdfmfb2mkn9c8or60nknlgl8' },
    s3: { REGION: '', BUCKET: ''},
    cc_apiGateway: 'https://api.cariclub.com/',
    cc_admin_url: 'https://admin.cariclub.com',
    cc_url: 'https://cariclub.com',
    apiGateway: {
        ENDPOINTS: [
            {
                name: 'https://i25elp2y59.execute-api.us-east-1.amazonaws.com/prod/getInfo',
                endpoint: '',
                region: ''    
            },
            {
                name: 'https://i25elp2y59.execute-api.us-east-1.amazonaws.com/prod/users',
                endpoint: '',
                region: ''    
            },
            {
                name: 'https://i25elp2y59.execute-api.us-east-1.amazonaws.com/prod/users',
                endpoint: '',
                region: ''    
            },
            {
                name: 'https://i25elp2y59.execute-api.us-east-1.amazonaws.com/prod/companies',
                endpoint: '',
                region: ''    
            },
            {
                name: 'https://i25elp2y59.execute-api.us-east-1.amazonaws.com/prod/getInfoP',
                endpoint: '',
                region: ''    
            }
        ]
    }
}

export default config;
